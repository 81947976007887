<template>
  <c-card :loading="cardloading" :title="`Detail Privilege ${parent.name}`">
    <template slot="action">
      <c-button @click="$refs.form.onSubmit()" :loading="isloading">Simpan</c-button>
    </template>
    <c-form ref="form" @submit="submitForm">
      <button hidden type="submit"></button>
      <tree :class="['my-tree',{'tree-dark': skin=='dark'}]" :keyboard-navigation="false" ref="tree" :data="options" :options="{checkbox: true}"></tree>
    </c-form>
  </c-card>
</template>

<script>
import Vue from 'vue'
import CForm from '@/utils/components/CForm'
import uniq from 'lodash/uniq'
import LiquorTree from 'liquor-tree'
import CButton from '@/utils/components/CButton.vue'
import CCard from '@/utils/components/CCard.vue'
Vue.use(LiquorTree)

export default {
  components:{
    CForm,
    CButton,
    CCard
  },
  data(){
    return {
      id: this.$route.params.id,
      parent:{
        name:''
      },
      selected:[],
      options: this.getPrivilege(),
      isloading: false,
      cardloading: false
    }
  },
  computed:{
    skin(){
      return this.$store.state.appConfig.layout.skin
    }
  },
  methods:{
    async getParentData(){
      const id = this.$route.params.id
      const {data} = await this.$http.get(`v1/users/show`,{params:{id:id}})
      this.parent = data
    },
    getNestedChildren(arr, parent) {
      let out = []
      for (let i in arr) {
        if (arr[i].parent == parent) {
          let children = this.getNestedChildren(arr, arr[i].id)

          if (children.length) {
            arr[i].children = children
          }
          out.push(arr[i])
        }
      }
      return out
    },
    async getPrivilege(){
      try {
        this.cardloading = true
        const {data:{previlage,def,cek}} = await this.$http.get(`v1/users/privilege`,{params:{id_user:this.$route.params.id}})
        const mapped = previlage.map((e) => {
          return {
            id: parseInt(e.id),
            text: e.label,
            parent: e.parent,
            state: {
              checked: cek.includes(e.id),
              expanded: def.includes(parseInt(e.id)),
            }
          }
        })
        const options = this.getNestedChildren(mapped,0)
        this.cardloading = false
        return Promise.resolve(options)
      } catch (error) {
        this.cardloading = false
        return Promise.reject(error)
      }
    },
    async submitForm(){
      this.isloading = true
      const raw = this.$refs.tree.checked()
      const checked = raw.map(e => e.id)
      let intermediate = []
      for (let i = 0; i < raw.length; i++) {
        const e = raw[i];
        if(e.parent) {
          intermediate.push(e.parent.id)
          if(e.parent.parent){
            intermediate.push(e.parent.parent.id)
            if(e.parent.parent.parent){
              intermediate.push(e.parent.parent.parent.id)
              if(e.parent.parent.parent.parent){
                intermediate.push(e.parent.parent.parent.parent.id)
                if(e.parent.parent.parent.parent.parent) intermediate.push(e.parent.parent.parent.parent.parent.id)
              }
            }
          }
        }
      }
      const parents = uniq(intermediate)
      const rawWithParent = [...checked,...parents]
      const params = {
        id: this.id,
        data: [rawWithParent,parents],
        name: this.parent.name
      }
      try {
        await this.$http.post(`v1/users/saveprivilege`,params.data,{params:{id_user:params.id,name:this.parent.username}})
        this.notify("Privilege Berhasil Disimpan!")
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    }
  },
  mounted(){
    this.getParentData()
  }
}
</script>

<style lang="scss">
  @import '~@/assets/scss/variables/_variables.scss';

  .my-tree {
    .tree-checkbox {
      width: 15px;
      height: 15px;
    }
    .tree-checkbox.checked, .tree-checkbox.indeterminate {
      border-color: $primary;
      background-color: $primary;
    }
    .tree-checkbox.checked:after {
      left: 4px;
      top: 2px;
      height: 4px;
      width: 3px;
    }
  }
  .tree-dark {
    .tree-checkbox {
      background: #283046;
    }
    .tree-anchor {
      color:#c6c8cd;
    }
    .tree-node:not(.selected)>.tree-content:hover {
      background: #161d31;
    }
    .tree-arrow.has-child:after {
      border-color: #c6c8cd;
    }
    .tree-node.selected > .tree-content{
      background-color: #161d31;
    }
  }
</style>